import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAllProducts } from '../utils/shopify';

const ProductContext = createContext();

export function ProductContextProvider({ children }) {
  const [products, setProducts] = useState([]);
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAllProducts();

        if (response.body?.data?.allShopifyProduct) {
          const productsData = response.body.data.allShopifyProduct.edges.map(({ node }) => ({
            ...node,
            images: node.images?.edges?.map(({ node: imageNode }) => imageNode) || [],
            variants: node.variants?.edges?.map(({ node: variantNode }) => variantNode) || []
          }));
          setProducts(productsData);
        }

        if (response.body?.data?.allShopifyCollection) {
          const collectionsData = response.body.data.allShopifyCollection.edges.map(({ node }) => ({
            ...node,
            products: node.products?.edges?.map(({ node: productNode }) => ({
              ...productNode,
              images: productNode.images?.edges?.map(({ node: imageNode }) => imageNode) || [],
              variants: productNode.variants?.edges?.map(({ node: variantNode }) => variantNode) || []
            })) || []
          }));
          setCollections(collectionsData);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const getProductsByCollection = (collectionId) => {
    const collection = collections.find(col => col.id === collectionId);
    return collection ? collection.products : [];
  };

  const getFeaturedProducts = () => {
    return products.filter(product => product.images && product.images.length > 0);
  };

  return (
    <ProductContext.Provider
      value={{
        products,
        collections,
        getProductsByCollection,
        getFeaturedProducts,
        loading,
        error,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

export function useProducts() {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useProducts must be used within a ProductContextProvider');
  }
  return context;
}

export default ProductContext;
