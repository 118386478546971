const fetch = require('node-fetch'); 

const storefrontAccessToken = process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN;
const domain = process.env.GATSBY_SHOP_NAME;

async function shopifyFetch({ query, variables = {} }) {
  const endpoint = `https://${domain}.myshopify.com/api/2025-01/graphql.json`;

  try {
    const result = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
      },
      body: JSON.stringify({ query, variables }),
    });
    
    return {
      status: result.status,
      body: await result.json(),
    };
  } catch (error) {
    console.error('Error:', error);
    return {
      status: 500,
      error: 'Error receiving data',
    };
  }
}

async function getAllProducts() {
  return shopifyFetch({
    query: `
      query GetProducts {
        allShopifyProduct: products(first: 100) {
          edges {
            node {
              id
              handle
              title
              description
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
                maxVariantPrice {
                  amount
                  currencyCode
                }
              }
              featuredImage {
                url
                altText
              }
              images(first: 20) {
                edges {
                  node {
                    url
                    altText
                  }
                }
              }
              variants(first: 100) {
                edges {
                  node {
                    id
                    availableForSale
                    price {
                      amount
                      currencyCode
                    }
                    compareAtPrice {
                      amount
                      currencyCode
                    }
                    selectedOptions {
                      name
                      value
                    }
                  }
                }
              }
              availableForSale
              vendor
              productType
            }
          }
        }
        allShopifyCollection: collections(first: 100) {
          edges {
            node {
              id
              handle
              title
              description
              products(first: 100) {
                edges {
                  node {
                    id
                    handle
                    title
                    description
                    priceRange {
                      minVariantPrice {
                        amount
                        currencyCode
                      }
                      maxVariantPrice {
                        amount
                        currencyCode
                      }
                    }
                    featuredImage {
                      url
                      altText
                    }
                    variants(first: 100) {
                      edges {
                        node {
                          id
                          availableForSale
                          price {
                            amount
                            currencyCode
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  });
}

async function getAllCollections() {
  return shopifyFetch({
    query: `
      query GetCollections {
        collections(first: 100) {
          edges {
            node {
              id
              handle
              title
              description
              products(first: 100) {
                edges {
                  node {
                    id
                    handle
                    title
                    description
                    priceRange {
                      minVariantPrice {
                        amount
                        currencyCode
                      }
                      maxVariantPrice {
                        amount
                        currencyCode
                      }
                    }
                    featuredImage {
                      url
                      altText
                    }
                    variants(first: 100) {
                      edges {
                        node {
                          id
                          availableForSale
                          price {
                            amount
                            currencyCode
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  });
} 

// Use CommonJS exports
module.exports = {
    shopifyFetch,
    getAllProducts, 
    getAllCollections
  };